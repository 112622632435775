body {
  margin: 0;
  font-family: var(--font-family), -apple-system, var(--font-family), BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pricingTable {
  margin-top: 30px;
  text-align: center;
  position: relative;
}

.pricingTable .pricing_heading:after {
  content: "";
  width: 36px;
  height: 29.5%;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  position: absolute;
  top: -1px;
  right: 0;
  z-index: 2;
  transform: skewY(45deg) translateY(18px);
  transition: all 0.4s ease 0s;
}

.pricingTable .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #000;
  text-transform: uppercase;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  padding: 15px 0 0;
  margin: 0 35px 0 0;
  transition: all 0.4s ease 0s;
}

.pricingTable .value {
  display: block;
  font-size: 35px;
  font-weight: 700;
  color: #000;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  padding: 5px 0 10px;
  margin: 0 35px 0 0;
  transition: all 0.4s ease 0s;
}

.pricingTable:hover .title,
.pricingTable:hover .value {
  color: #fff;
}

.pricingTable .month {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.pricingTable .content {
  border-left: 1px solid #f2f2f2;
  position: relative;
}

.pricingTable .content:after {
  content: "";
  width: 35px;
  height: 100%;
  background: #f8f8f8;
  box-shadow: 9px 9px 20px #ddd;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: skewY(-45deg) translateY(-18px);
  transition: all 0.4s ease 0s;
}

.pricingTable .content ul {
  padding: 0;
  margin: 0 35px 0 0;
  list-style: none;
  background: #fff;
}

.pricingTable .content ul li {
  display: block;
  font-size: 15px;
  color: #333;
  line-height: 23px;
  padding: 11px 0;
  border-bottom: 1px solid #f2f2f2;
}

.pricingTable .link {
  background: #fff;
  padding: 20px 0;
  margin-right: 35px;
  border-bottom: 1px solid #f2f2f2;
}

.pricingTable .link a {
  display: inline-block;
  padding: 9px 20px;
  font-weight: 700;
  color: #A389D4;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #A389D4;
  border-radius: 10px;
  background: #fff;
  transition: all 0.4s ease 0s;
}

.pricingTable:hover .link a {
  color: #fff;
  background: #06D6A0;
  border: 1px solid #06D6A0;
}

.pricingTable:hover .pricing_heading:after,
.pricingTable:hover .title,
.pricingTable:hover .value {
  background: #06D6A0;
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 35px;
  }
}

::marker {
  font-weight: bold;
}